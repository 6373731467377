export enum ResetFeatureFlags {
    IsPersonalResetEnabled = 'isPersonalResetEnabled',
    IsResetInLibraryEnabled = 'isResetTabEnabled',
    IsLearnToResetRedirectEnabled = 'isLearnToResetRedirectEnabled',
    IsResetAudioSearchEnabled = 'IsResetAudioSearchEnabled',
    IsNewSharePersonalizedResetPopupEnabled = 'IsNewSharePersonalizedResetPopupEnabled',
    HasNewPlantMechanics = 'newPlantMechanics',
    HasAutomaticPersonalResets = 'hasAutomaticPersonalResets',
    IsResetFeedbackEnabled = 'isResetFeedbackEnabled',
    IsResetAutoPlayEnabled = 'IsResetAutoPlayEnabled',
    IsSkipIOSResetPosterEnabled = 'isSkipIOSResetPosterEnabled',
    IsSkipAndroidResetPosterEnabled = 'isSkipAndroidResetPosterEnabled'
}
