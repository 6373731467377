import { Avo, AvoTypes } from '@thriveglobal/thrive-web-tracking'
import React, { ComponentType, useCallback } from 'react'
import { ShareResetPopupProps } from '../../components/elements/ShareResetPopup'

export type ShareResetPopupTrackableProps = ShareResetPopupProps & {
    id: string
}

function withShareResetPopupTracking(
    ShareResetPopup: ComponentType<ShareResetPopupTrackableProps>,
    {
        contentFormatType
    }: { contentFormatType: AvoTypes.ContentFormatTypeValueType }
) {
    return React.memo(function ShareResetPopupTrackable(
        props: ShareResetPopupTrackableProps
    ) {
        const { onTeamsShared, ...restProps } = props
        const { id, title, url } = props

        const trackTeamsShared = useCallback(() => {
            Avo.contentShared({
                featureType: 'library',
                activityType: 'reset_shared',
                contentType: 'reset',
                contentFormatType,
                resetType: 'reset',
                contentId: id,
                contentTitle: title,
                contentUrl: url,
                contentSubtype: null,
                contentSource: null,
                pageSection: null
            })
        }, [id, title, url])

        const onTeamsSharedTrackable = useCallback(
            (...args) => {
                trackTeamsShared()

                onTeamsShared?.call(null, args)
            },
            [onTeamsShared, trackTeamsShared]
        )

        return (
            <ShareResetPopup
                {...restProps}
                onTeamsShared={onTeamsSharedTrackable}
            />
        )
    })
}

export default withShareResetPopupTracking
