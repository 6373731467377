import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { ComponentType, useCallback } from 'react'
import { PostThriveAudioResetProps } from '../../components/features/ThriveAudioReset/PostThriveAudioReset/PostThriveAudioReset'
import { ThriveResetAudio } from '../../graphql/generated/autogenerated'

export type PostThriveResetTrackableProps = PostThriveAudioResetProps

function withPostThriveAudioResetTracking(
    PostThriveAudioReset: ComponentType<PostThriveAudioResetProps>
) {
    return React.memo(function PostThriveAudioResetTrackable(
        props: PostThriveResetTrackableProps
    ) {
        const { onNext, onAutoPlayToggle, ...restProps } = props

        const trackOnNext = useCallback((nextReset: ThriveResetAudio) => {
            Avo.promptSelected({
                featureType: 'content',
                activityType: 'reset_watch_next_selected',
                contentType: 'reset',
                displayText: null,
                contentFormatType: 'audio',
                contentId: nextReset?.id,
                contentTitle: nextReset?.name,
                contentSource: null,
                contentSubtype: null,
                contentUrl: null,
                isOnboarding: false,
                resetId: nextReset?.id,
                resetName: nextReset?.name,
                tabName: null,
                userId_: null,
                tstPlatform: null,
                checkInDate: null,
                isAutomaticCheckin: null,
                microstepBody: null,
                microstepId: null,
                microstepName: null,
                notificationCount: null,
                nudgeType: null
            })
        }, [])

        const onNextTrackable: PostThriveResetTrackableProps['onNext'] =
            useCallback(
                (...args) => {
                    const [nextThriveReset] = args
                    trackOnNext(nextThriveReset)

                    return onNext?.call(null, ...args)
                },
                [onNext, trackOnNext]
            )

        const trackOnAutoPlayToggle = useCallback(
            (thriveReset: ThriveResetAudio, isAutoplay: boolean) => {
                Avo.promptSelected({
                    featureType: 'content',
                    activityType: isAutoplay
                        ? 'reset_autoplay_toggled_on'
                        : 'reset_autoplay_toggled_off',
                    contentType: 'reset',
                    displayText: null,
                    contentFormatType: 'audio',
                    contentId: thriveReset?.id,
                    contentTitle: thriveReset?.name,
                    contentSource: null,
                    contentSubtype: null,
                    contentUrl: null,
                    isOnboarding: false,
                    resetId: thriveReset?.id,
                    resetName: thriveReset?.name,
                    tabName: null,
                    userId_: null,
                    tstPlatform: null,
                    checkInDate: null,
                    isAutomaticCheckin: null,
                    microstepBody: null,
                    microstepId: null,
                    microstepName: null,
                    notificationCount: null,
                    nudgeType: null
                })
            },
            []
        )

        const onAutoPlayToggleTrackable: PostThriveResetTrackableProps['onAutoPlayToggle'] =
            useCallback(
                (...args) => {
                    trackOnAutoPlayToggle(...args)

                    return onAutoPlayToggle?.call(null, ...args)
                },
                [onAutoPlayToggle, trackOnAutoPlayToggle]
            )

        return (
            <PostThriveAudioReset
                {...restProps}
                onNext={onNextTrackable}
                onAutoPlayToggle={onAutoPlayToggleTrackable}
            />
        )
    })
}

export default withPostThriveAudioResetTracking
